import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { logo, title } from '../Config/Config';
import { Close, OpenInNew, PivotTableChart } from '@mui/icons-material';
import { Cookies, getCookie } from 'typescript-cookie';
import { HeaderItem, HeaderItemtools } from '../AllContent/Content';
import toasted from '../Config/toast';
import { Offcanvas } from 'react-bootstrap';


const Header = () => {
     const navigate = useNavigate();
     const location = useLocation();


     const [sticky, setsticky] = useState(false);

     const [isActive, setIsActive] = useState(false);

     const handleClick = () => {
          const isMobile = window.matchMedia('(max-width: 768px)').matches;

          if (isMobile) {
               setIsActive(!isActive);

               if (!isActive) {
                    document.body.classList.add('add-class-body');
               } else {
                    document.body.classList.remove('add-class-body');
               }
          }
     };


     

     useEffect(() => {
          const handleScroll = () => {
               const headerBanner = document.getElementById('header-banner');
               if (headerBanner) {

                    if (window.scrollY > 400) {
                         setsticky(true);

                    } else {
                         setsticky(false);
                    }
               }
          };

          window.addEventListener('scroll', handleScroll);

          return () => {
               window.removeEventListener('scroll', handleScroll);
          };
     }, []);


     const handleLogout = () => {
          Cookies.remove("auth");
          toasted.error("You have been logged out.");
          navigate("/login");
     };

     const auth = getCookie("auth");

     useEffect(() => {
          const handleLogout = () => {
               Cookies.remove("auth");
               toasted.error("You have been logged out.");
               navigate("/login");
          };

          const startLogoutTimer = () => {
               setTimeout(() => {
                    handleLogout();
               }, 5 * 60 * 60 * 1000); // 5 hours
          };

          startLogoutTimer();

          return () => {
               // Clear timeout if necessary
          };
     }, [navigate]); // Add 'navigate' to the dependency array

     const [show, setShow] = useState(false);

     const handleClose = () => setShow(false);
     const handleShow = () => setShow(true);

     const MyCodeFuntion = () => {
          localStorage.removeItem('isLocked');
     };

     const listRef = useRef<HTMLUListElement>(null);

     const handleScroll = (event: React.WheelEvent) => {
          if (listRef.current) {
               // Vertical scroll ko horizontal scroll me convert kar rahe hain
               listRef.current.scrollLeft += event.deltaY;
          }
     };


     // if (location.pathname === '/tools') {
     //      console.log('Path match.');
     //      handleShow();

     // } else {
     //      console.log('Path does not match.');
     // }

     return (
          <>
               <header className={location.pathname === '/websites' || location.pathname === '/code' || location.pathname === '/view' || location.pathname === '/about' ? "--full-header" : 'red' && sticky ? "sticky-header" : ''} id='header-banner' >
                    <div className='__container' >
                         <Link to="/" className='_logo'>
                              <img src={logo} alt={title + "logo"} />
                         </Link>

                         <div className='__list'>
                              <ul className='nav'>
                                   <li className='__phoneDropHeader'>
                                        <div className='__logs'>
                                             <img src={logo} alt={title + "logo"} />
                                        </div>
                                        <button type='button' className='btn-toggle' onClick={() => handleClick()}> <Close /></button>
                                   </li>
                                   {/* {HeaderItem.map((item, index) => (
                                        <li className='nav-item' key={index} onClick={() => handleClick()}>
                                             {item.button ?
                                                  auth ?
                                                       <button type='button' className='nav-button' onClick={handleLogout}>Logout</button>
                                                       :
                                                       <Link to={item.path} className="nav-button"> {item.label} </Link>
                                                  :
                                                  item.inout ?
                                                       <Link to={auth ? '/view' : item.path} className="nav-link"> {auth ? "Overview" : item.label} </Link>
                                                       :
                                                       item.exLink ?
                                                            <Link to={'#'} onClick={handleShow} className="nav-link"> {item.label} </Link>
                                                            :
                                                            item.mecode ?
                                                                 <Link to={item.path} onClick={MyCodeFuntion} className="nav-link"> {item.label} </Link>
                                                                 :
                                                                 <Link to={item.path} className="nav-link"> {item.label} </Link>
                                             }
                                        </li>
                                   ))} */}
                              </ul>


                              <button type='button' className='btn-toggle' onClick={() => handleClick()} > <PivotTableChart /></button>
                         </div>
                    </div>
               </header>



               <Offcanvas show={show} onHide={handleClose} placement="bottom" scroll={false} backdrop={true} >
                    <Offcanvas.Header closeButton>
                         <Offcanvas.Title>Tools</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body onWheel={handleScroll}>
                         <ul ref={listRef}>
                              {HeaderItemtools.map((item, index) => {
                                   return (
                                        <li className='nav-item' key={index}>
                                             <Link to={item.path} className='nav-link' target='_blank'>
                                                  <span> {item.icon} </span>
                                                  <p>{item.label}</p>
                                                  <OpenInNew className='__extrnal_link' /></Link>
                                        </li>
                                   )
                              })}
                         </ul>

                         {HeaderItemtools.length > 5 && (
                              <div className='__footer-off'>
                                   <h5> Scroll Down seen More tools  </h5>
                              </div>
                         )}
                    </Offcanvas.Body>
               </Offcanvas>
          </>

     );
}

export default Header;
