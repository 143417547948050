import React, { useEffect, useState } from 'react';
import { logo } from './Config';

const Loader = (props: any) => {
     const [loading, setLoading] = useState<boolean>(true);
     const [endAnimation, setEndAnimation] = useState<boolean>(false);

     useEffect(() => {
          const fetchData = async () => {
               try {
                    document.body.classList.add('Loader-start');
                    await new Promise(resolve => setTimeout(resolve, props.timer));

                    setEndAnimation(true);
                    setTimeout(() => {
                         setLoading(false);
                         document.body.classList.remove('Loader-start');
                    }, 1000); // Match this to the duration of the wave-out animation
               } catch (error) {
                    console.error('Error fetching data', error);
               }
          };

          fetchData();
     }, [props.timer]);

     return (
          <>
               {loading && (
                    <div className={`__loader ${endAnimation ? '__loader-end' : ''}`}>
                         <img src={logo} alt='logo' />
                    </div>
               )}
          </>
     );
};

export default Loader;
