import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Environment, Float, OrbitControls, Sky, Sparkles, Stars, Text } from '@react-three/drei';
import * as THREE from 'three';
import { LayerMaterial, Depth } from 'lamina';

function Striplight(props) {
    return (
        <mesh {...props}>
            <boxGeometry />
            <meshBasicMaterial color="white" />
        </mesh>
    );
}

function AnimatedMesh({ setCurrentColor }) {
    const meshRef = useRef();
    const [targetColor, setTargetColor] = useState(new THREE.Color('darkgoldenrod'));
    const [p, setP] = useState(2); // Initial values for p and q
    const [q, setQ] = useState(1);

    // Cycle color and geometry values
    useEffect(() => {
        const colors = ['darkgoldenrod', 'aqua', 'green', 'crimson', 'purple', 'blue', 'black', 'gold', 'silver', 'black'];
        let colorIndex = 0;

        const colorChangeInterval = setInterval(() => {
            colorIndex = (colorIndex + 1) % colors.length;
            setTargetColor(new THREE.Color(colors[colorIndex]));

            // Cycle `p` and `q` values
            setP(prev => (prev < 40 ? prev + .6 : 1)); // p cycles between 1 and 9
            setQ(prev => (prev < 40 ? prev + 3 : 1)); // q cycles between 1 and 400

        }, 1000); // Change target color and p, q every 1 second


        return () => clearInterval(colorChangeInterval);
    }, []);

    useFrame((_, delta) => {
        if (meshRef.current) {
            // Smoothly transition to the target color
            meshRef.current.material.color.lerp(targetColor, delta * 15);
            // Update the current color
            setCurrentColor(meshRef.current.material.color);
        }
    });

    return (
        <Float position={[0, 2.45, 0]} speed={22} scale={1.4} rotationIntensity={2} floatIntensity={1}>
            <mesh ref={meshRef} castShadow receiveShadow>
                <boxGeometry args={[2, 2, 2]} />
                <torusKnotGeometry args={[1, 0.30, 400, 99, p, q]} />
                <meshStandardMaterial roughness={0.1} metalness={0.925} />
            </mesh>

        </Float>
    );
}

function BackgroundColorUpdater({ targetColor, appRef }) {
    const [backgroundColor, setBackgroundColor] = useState(new THREE.Color('darkgoldenrod'));
    let rotationAngle = 0; // Initialize rotation angle

    useFrame((_, delta) => {
        // Update the background color based on the target color
        backgroundColor.lerp(targetColor, delta * 2);
        const hexColor = `#${backgroundColor.getHexString()}`;

        // Update rotation angle (360 degrees over time)
        rotationAngle += delta * 50; // Adjust the speed of rotation here (degrees per second)
        if (rotationAngle >= 360) rotationAngle -= 360; // Reset angle to avoid overflow

        // Apply the linear gradient with the rotating angle
        appRef.current.style.background = `linear-gradient(${rotationAngle}deg, ${hexColor}, white)`;
    });

    return null; // This component doesn't render anything visible
}

function Status(props) {
    return (
        <Text fontSize={12} letterSpacing={-0.015} color="black" {...props} className="__name" alpha={0}>
            {props.demotxt}
        </Text>
    );
}

export default function CanvaSection(props) {
    const [targetColor] = useState(new THREE.Color('darkgoldenrod'));
    const appRef = useRef();
    const [currentColor, setCurrentColor] = useState(new THREE.Color('darkgoldenrod'));

    return (
        <div ref={appRef} className={`__homeCanvas ${props.scrolled ? "qw5t2" : ""}`}>
            <Canvas dpr={[1, 2]} camera={{ position: [0, 0, 5] }}>
                <OrbitControls minPolarAngle={Math.PI / 1.8} maxPolarAngle={Math.PI / 1.8} enableZoom={false} />
                {props.scrolled ? '' : <Status position={[0, 2, -11]} demotxt={"#thedc"} />}

                <Sparkles />
                <group position={[0, -2, 0]}>
                    <AnimatedMesh setCurrentColor={setCurrentColor} />
                </group>

                <Stars
                    radius={100}
                    depth={50}
                    count={5000}
                    factor={4}
                    saturation={1}
                    fade
                    colorA={"#000"}
                />

                <Environment resolution={64}>
                    <Striplight position={[10, 2, 0]} scale={[1, 3, 10]} />
                    <Striplight position={[-10, 2, 0]} scale={[1, 3, 10]} />
                    <mesh scale={100}>
                        <sphereGeometry args={[1, 64, 64]} />
                        <LayerMaterial side={THREE.BackSide}>
                            <Depth colorA="pink" colorB="white" alpha={12} mode="normal" near={0} far={300} origin={[100, 100, 100]} />
                        </LayerMaterial>
                    </mesh>
                </Environment>
                <directionalLight position={[2, 5, 1]} />
                <BackgroundColorUpdater targetColor={currentColor} appRef={appRef} />


            </Canvas>
        </div>
    );
}
