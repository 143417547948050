import React, { useEffect, useState } from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { Indexdata } from '../../AllContent/Content';


const Brand = () => {
     const [scrollPosition, setScrollPosition] = useState(0);


     useEffect(() => {
          const handleScroll = () => {
               const position = window.scrollY;
               setScrollPosition(position);
          };

          window.addEventListener('scroll', handleScroll);

          return () => {
               window.removeEventListener('scroll', handleScroll);
          };
     }, []);

     return (
          <ParallaxProvider>
          <section className='brand'>

               <div className='animation-sec'>
                    <div className='heding-card'>
                         <h1
                              className="creative-header"
                              style={{ transform: `translateX(${Math.min(scrollPosition - 780, 500)}px)` }} >
                              <span>#{Indexdata.Brand.head.heading}</span>

                         </h1>
                    </div>
               </div>

               {/* animation text */}
               <div className='animation-sec'>
                    <div className='sub-heading-card'> <h2>{Indexdata.Brand.head.subHeading}</h2> </div>
               </div>


               {/* simple card */}
               <div className='__container'>
                    <div className='row'>
                         {Indexdata.Brand.CretiveCard.map((item, index) => (
                              <div className='col-md-4 card-container' key={index}>
                                   <Parallax speed={index % 2 === 0 ? -10 : -20}>
                                        <div className="creative-card" data-theme={item.id}>
                                             <div className='__icon'>
                                                  <p>{item.icon}</p>
                                             </div>
                                             <h4>{item.heading} </h4>
                                             <p className='pragraph-txt'>{item.pragraph}</p>
                                        </div>
                                   </Parallax>
                              </div>
                         ))}
                    </div>
               </div>
          </section>
          </ParallaxProvider>
     );
}

export default Brand;

