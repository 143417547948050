import React, { useEffect, useRef, useState } from 'react';
import __img_icon from '../../Assets/Image/gold-Feather.png';
import { NorthEastRounded } from '@mui/icons-material';
import { Indexdata } from '../../AllContent/Content';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { projects } from '../../AllContent/Portfolio';

const Interesting = () => {
     
     const generateRandomColor = () => {
          return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
     };

     const [rotation, setRotation] = useState(0);
     const [lastScrollY, setLastScrollY] = useState(0);
     const [isInView, setIsInView] = useState(false);
     const [currentColor, setCurrentColor] = useState(generateRandomColor());
     const sectionRef = useRef<HTMLDivElement | null>(null);
     const maxRotation = 350; // Maximum rotation in degrees

     useEffect(() => {
          const currentRef = sectionRef.current; // Store the current ref

          const observer = new IntersectionObserver(
               ([entry]) => {
                    setIsInView(entry.isIntersecting);
               },
               {
                    root: null, // Use the viewport as the root
                    threshold: 0.2, // Trigger when 20% of the section is visible
               }
          );

          if (currentRef) {
               observer.observe(currentRef);
          }

          return () => {
               if (currentRef) {
                    observer.unobserve(currentRef);
               }
          };
     }, []);

     useEffect(() => {
          const handleScroll = () => {
               if (!isInView) return;

               const currentScrollY = window.scrollY;

               if (currentScrollY > lastScrollY) {
                    // Scrolling down
                    setRotation((prev) => Math.min(prev + 1, maxRotation));
               } else if (currentScrollY < lastScrollY) {
                    // Scrolling up
                    setRotation((prev) => Math.max(prev - 1, -maxRotation));
               }

               setLastScrollY(currentScrollY);
          };

          window.addEventListener('scroll', handleScroll);

          return () => {
               window.removeEventListener('scroll', handleScroll);
          };
     }, [lastScrollY, isInView]);

     const handleSlideChange = () => {
          setCurrentColor(generateRandomColor());
     };

     const sliderData = projects.slides.slice(-3).reverse();

     return (
          <ParallaxProvider>
               <section className="Interesting-sec" ref={sectionRef}>
                    <div className="__container">
                         <div className="__animation-sec">
                              <div className="__father" style={{ transform: `translate(10%, -30%) rotate(${rotation}deg)` }}>
                                   <img src={__img_icon} alt="icon" />
                              </div>
                              <h3 style={{ transform: `translateX(-${rotation + 100}px)` }}>
                                   {Indexdata.Interesting.head.heading}
                              </h3>
                              <h1 style={{ transform: `translateX(${rotation + 100}px)` }}>
                                   {Indexdata.Interesting.head.SubHeading}
                              </h1>

                              <p>{Indexdata.Interesting.head.pragraph}</p>
                         </div>

                         <div className="__boxs">
                              <Parallax speed={20}>
                                   <Swiper
                                        onSlideChange={handleSlideChange}  // Add this event handler
                                        onSwiper={handleSlideChange}       // Add this event handler to trigger on initial render
                                        loop={true}
                                        grabCursor={true}
                                        spaceBetween={100}
                                        autoplay={{
                                             delay: 2500,
                                             disableOnInteraction: false,
                                        }}
                                        pagination={{
                                             clickable: true,
                                        }}
                                        navigation={true}
                                        breakpoints={{
                                             640: {
                                                  slidesPerView: 1,
                                             },
                                             768: {
                                                  slidesPerView: 2,
                                             },
                                             1024: {
                                                  slidesPerView: 2,
                                             },
                                        }}
                                        modules={[Autoplay, Navigation]}
                                        className="mySwiper"
                                   >
                                        {sliderData.map((item, index) => (
                                             <SwiperSlide key={index}>
                                                  <div className="__slider-card" style={{ '--random-color': currentColor } as React.CSSProperties}>
                                                       <div className="__img-sec">
                                                            <img src={item.img} alt={item.name} loading="lazy" />
                                                            <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                                                       </div>
                                                       <div className="__text-sec">
                                                            <h5>{item.name}</h5>
                                                            <Link
                                                                 to={item.Link}
                                                                 target={item.Link !== '' && item.Link !== '#' ? '_blank' : undefined}
                                                                 className="btn"
                                                            >
                                                                 View <NorthEastRounded />
                                                            </Link>
                                                       </div>
                                                  </div>
                                             </SwiperSlide>
                                        ))}
                                   </Swiper>

                                   <div className="__more_btn d-flex align-items-center justify-content-center">
                                        <Link to="/portfolio" className="btn-common">
                                             View More
                                        </Link>
                                   </div>
                              </Parallax>
                         </div>
                    </div>
               </section>
          </ParallaxProvider>
     );
};

export default Interesting;
