import Brand from './indexComponents/Brand';
import Loader from '../Config/Loader';
import Banner from './indexComponents/Banner';
import About from './indexComponents/About';
import Question from './indexComponents/Question';
import Interesting from './indexComponents/Interesting';


const Index = () => {

     return (
          <>
               <Loader timer={3000} />
               <Banner />

                    <div className='outer-wrrapr'>
                         {/* <LineAnimation/> */}
                         <About />
                         <Brand />
                         <Interesting/>
                         <Question/>
                    </div>
          </>
     );
}

export default Index;


