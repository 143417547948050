import React from 'react';
import avtar from '../../Assets/Image/avtar.png';
import { Indexdata, socialMedia } from '../../AllContent/Content';
import { Link } from 'react-router-dom';
import { Camera, SmartToy } from '@mui/icons-material';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';


const About = (props: any) => {

     const lastTwoSocialMedia = socialMedia.slice(-2);


     return (
          <ParallaxProvider>
               <section className='second-sec'>
                    <div className='__content'>
                         <div className='row'>
                              <div className='col-12'>
                                   <Parallax speed={5}>
                                        <div className='__infocard text-center'>
                                             <div className='__info'>
                                                  <div className='__left'>
                                                       {
                                                            props?.imgTdc ?
                                                                 <img src={props.imgTdc} alt='icon' style={{width: '200px', height: '300px', borderRadius: '20px', objectFit: 'cover' }} />
                                                                 :
                                                                 <img src={avtar} alt='icon' />
                                                       }

                                                       <div className='__infotxt'>
                                                            <h6> {Indexdata.about.head.heading} <span>( {Indexdata.about.head.secondName} )</span></h6>
                                                            <p> {Indexdata.about.head.subheading}</p>
                                                       </div>
                                                  </div>
                                             </div>

                                             <h3>{Indexdata.about.cardheding}.</h3>
                                             <h5 dangerouslySetInnerHTML={{ __html: Indexdata.about.cardPragraph }} />
                                        </div>
                                   </Parallax>
                              </div>

                              <div className='col-lg-6'>
                                   <Parallax speed={-4}>
                                        <div className='__Linkscard'>
                                             <ul className='nav'>
                                                  {socialMedia.map((item, index) => (
                                                       <li className='nav-item' key={index}>
                                                            <Link className='nav-link' target='_blank' to={item.link}>
                                                                 {item.icon}
                                                                 <span>{item.name}</span>
                                                            </Link>
                                                       </li>
                                                  ))}
                                             </ul>
                                        </div>
                                   </Parallax>
                              </div>

                              <div className='col-lg-6'>
                                   <Parallax speed={-4}>
                                        <div className='__Linkscard'>
                                             {lastTwoSocialMedia.map((item, index) => (
                                                  <Link to={item.link} key={index} target='_blank' className='icon-link'>
                                                       {item.icon}
                                                       <span>{item.name}</span>
                                                  </Link>
                                             ))}
                                             <Link to="/websites" target='_blank' className='icon-link'> <SmartToy /></Link>
                                             <Link to="/about" className='btn'> <Camera /> About me</Link>
                                        </div>
                                   </Parallax>
                              </div>
                         </div>
                    </div>
               </section>
          </ParallaxProvider>
     );
}

export default About;
