import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import './Assets/scss/style.scss';
import Header from './Layout/Header';
import Index from './Public/Index';
import Login from './User/Login';
import Overview from './Private/Overview';
import PrivateRoutes from './Config/PrivateRoutes';
import { Toaster } from 'react-hot-toast';
import Footer from './Layout/Footer';
import HelpAi from './Public/Ai/HelpAi';
import Coding from './Public/Coding';
import AboutPage from './Public/About';
import SvgPath from './SvgPath';
import View from './Me/View';
import GradientMeker from './Tools/Gradients/GradientMeker';
import ColosTool from './Tools/Gradients/ColosTool';
import Task from './Public/TaskManager/Task';
import Download from './Tools/DownLoader/Download';
import R3f from './Docs/R3f';
import Homepage from './Home/Homepage';
// designing creatives


function App() {


    // Login page.....
    function LoginLayout() {
        return (
            <>
                {/* <Header /> */}
                <Outlet />
            </>
        );
    }

    function HomeSections() {
        return (
            <>
            
                <Outlet />
            </>
        );
    }


    // Public page.....
    function PublicLayout() {
        return (
            <>
                <Header />
                <Outlet />
                <Footer />
            </>
        );
    }

    function PrivateLayout() {
        return (
            <>
                {/* <Header /> */}
                <Outlet />
                <Footer />
            </>
        );
    }



    return (

        <Router>
            <Routes>
                <Route element={<LoginLayout />}>
                    <Route path='/login' element={<Login />} />
                </Route>

                <Route element={<PublicLayout />}>
                    {/* <Route path='*' element={<Index />} /> */}
                    <Route path='/' element={<Index />} />
                    <Route path='/about' element={<AboutPage />} />
                    <Route path='/websites' element={<HelpAi />} />
                    <Route path='/code' element={<Coding />} />
                    <Route path='/lines' element={<SvgPath />} />
                    <Route path='/me' element={<View />} />
                    <Route path='/gradients' element={<GradientMeker />} />
                    <Route path='/colos' element={<ColosTool />} />
                    <Route path='/task' element={<Task />} />
                    <Route path='/download' element={<Download />} />
                    <Route path='/docs' element={<R3f />} />     
                </Route>

                <Route element={<HomeSections />}>
                <Route path='/home' element={<Homepage />} />
                </Route>


                <Route element={<PrivateLayout />}>
                    <Route element={<PrivateRoutes />}>
                        <Route path='/view' element={<Overview />} />
                    </Route>
                </Route>
            </Routes>
            <Toaster />
            {/* <FollowingDotCursor/> */}
        </Router>
    );
}

export default App;
