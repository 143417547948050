import { nanoid } from 'nanoid';

// "homepage": "https://thedchere.github.io/-",

export const projects = {

     slides: [
          {
               id: nanoid(),
               img: "https://uithemez.com/i/hubfolio_HTML/preview/assets/imgs/demos/7.jpg",
               name: "Website Name",
               Link: "#"
          },
          {
               id: nanoid(),
               img: "https://uithemez.com/i/hubfolio_HTML/preview/assets/imgs/demos/7.jpg",
               name: "Second Last Project",
               Link: "#"
          },
          {
               id: nanoid(),
               img: "https://uithemez.com/i/hubfolio_HTML/preview/assets/imgs/demos/7.jpg",
               name: "Last Project",
               Link: "#"
          },
          {
               id: nanoid(),
               img: "https://uithemez.com/i/hubfolio_HTML/preview/assets/imgs/demos/7.jpg",
               name: "Coming Soon",
               Link: "#"
          },
     ],

}