import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { websites } from '../../AllContent/Data';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Loader from '../../Config/Loader';

const shuffleArray = (array: any[]) => {
     return array
          .map((item) => ({ item, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ item }) => item);
};

const HelpAi = () => {
     const [searchQuery, setSearchQuery] = useState<string>('');
     const [visibleCount, setVisibleCount] = useState<number>(12);

     const filteredWebsites = websites.filter((site) =>
          site.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          site.category.toLowerCase().includes(searchQuery.toLowerCase())
     );

     const getRandomCardClass = () => {
          const classes = ['qw-1', 'qw-2', 'qw-3'];
          return classes[Math.floor(Math.random() * classes.length)];
     };

     const shuffledWebsites = shuffleArray(filteredWebsites);
     const visibleWebsites = shuffledWebsites.slice(0, visibleCount);

     // Adjusted condition to check for both filteredWebsites and visibleWebsites
     const noResults = filteredWebsites.length === 0 && visibleWebsites.length === 0;


     return (
          <>
               <Loader timer={2000} />

               <section className='ai'>
                    <ParallaxProvider>
                         <Parallax speed={5}>
                              <div className='__headings'>
                                   <div className='__container'>
                                        <div className='row'>
                                             <div className='col-md-6'>
                                                  <div className='__left'>
                                                       <h2>Explore Your Favorite {websites.length}+ Websites</h2>
                                                       <h4>Search by URL, name, category, or type of work to discover the right site or project in an instant.</h4>
                                                  </div>
                                             </div>

                                             <div className='col-md-6'>
                                                  <div className='__select'>
                                                       <input
                                                            type='search'
                                                            placeholder='Search Websites'
                                                            value={searchQuery}
                                                            onChange={(e) => setSearchQuery(e.target.value)} />
                                                  </div>
                                             </div>
                                        </div>


                                   </div>
                              </div>
                         </Parallax>

                         <div className='__container'>
                              <div className='__sec'>
                                   <div className='__cards'>
                                        <div className='__results'>
                                             <p>Show Item: {visibleWebsites.length}</p>
                                             {/* <p>Total Results: </p> */}
                                        </div>
                                        <TransitionGroup className='row'>
                                             {!noResults ? (
                                                  visibleWebsites.map((item, index) => (
                                                       <CSSTransition key={index} timeout={500} classNames='fade'>
                                                            <div className='col-xl-3 col-lg-4 col-md-6' >
                                                                 <Parallax speed={index % 2 === 0 ? 0 : 8}>
                                                                      <div className={`card-qw ${getRandomCardClass()}`}>
                                                                           <Link
                                                                                to={item.url}
                                                                                className='stretched-link'
                                                                                target='_blank'
                                                                           ></Link>
                                                                           <div className='card-header'>
                                                                                <img src={item.imageUrl} alt={item.name + '-Logo'} />
                                                                           </div>
                                                                           <div className='card-body'>
                                                                                <h5>{item.name}</h5>
                                                                                <p>{item.description}</p>
                                                                           </div>
                                                                      </div>
                                                                 </Parallax>
                                                            </div>
                                                       </CSSTransition>
                                                  ))
                                             ) : (
                                                  <div className='col-12'>
                                                       <div className='__section-height'>
                                                            <p className='text-center'>No results found.</p>
                                                       </div>
                                                  </div>
                                             )}
                                        </TransitionGroup>

                                        {visibleCount < filteredWebsites.length && !noResults && (
                                             <div className='text-center mt-5'>
                                                  <button className='btn-common' onClick={() => setVisibleCount(visibleCount + 8)}>
                                                       See More
                                                  </button>
                                             </div>
                                        )}
                                   </div>
                              </div>
                         </div>
                    </ParallaxProvider>
               </section>
          </>
     );
};

export default HelpAi;