import React from 'react';
import { Link } from 'react-router-dom';
import { Indexdata } from '../../AllContent/Content';
import { getCookie } from 'typescript-cookie';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

const Banner = () => {

    const auth = getCookie("auth");



    return (
        <ParallaxProvider>
            <Parallax speed={-10}>
                <section className='banner'>
                    <div className='__contant'>
                        <Parallax speed={-10}>
                            <h1> <span>{Indexdata.Banner.Heading.HeadActive}</span><br /> {Indexdata.Banner.Heading.Pragraph}</h1>
                            <p>{Indexdata.Banner.pragrph}</p>
                            <div className='btns'>
                                <Link to={auth ? Indexdata.Banner.Button.authUrl : Indexdata.Banner.Button.btnUrl} className='btn-common'> {Indexdata.Banner.Button.BtnText}  </Link>
                            </div>
                        </Parallax>

                    </div>
                </section>
            </Parallax>
        </ParallaxProvider>


    );
}

export default Banner;
