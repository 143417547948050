import React, { useEffect, useState } from 'react';
import { EmailAddress, logo, PasswordAddress } from '../Config/Config';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../Config/Loader';
import { getCookie, setCookie } from 'typescript-cookie';
import toasted from '../Config/toast';
import { Visibility, VisibilityOff } from '@mui/icons-material';


const Login = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState(EmailAddress);
    const [password, setPassword] = useState(PasswordAddress);
    const [passwordvisibilty, setPasswordvisibilty] = useState(false);


    useEffect(() => {
        // Check if user is already authenticated
        const authCookie = getCookie('auth');
        if (authCookie === 'authenticated') {
            navigate('/');
        }
    }, [navigate]);


    const handleLogin = (e: any) => {
        const correctEmail = EmailAddress;
        const correctPassword = PasswordAddress;
        e.preventDefault();

        if (email === correctEmail && password === correctPassword) {
            setCookie("auth", "authenticated", { expires: 5 / 24 });
            toasted.success("Login successful!");
            navigate("/view");
        } else {
            if (email !== correctEmail || password !== correctPassword) {
                toasted.error("Incorrect email or password.");
            }
        }

    };

    
    return (
        <>
            <Loader timer={1000} />

            <div className='__login'>
                <div className='__contaer'>
                    <div className='__logo'>
                        <Link to='/'>
                            <img src={logo} alt='logo' />
                        </Link>
                    </div>
                    <form className='form' onSubmit={handleLogin}>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='from-group'>
                                    <label htmlFor='email'> Email</label>
                                    <input type='email' className='form-control' onChange={(e) => setEmail(e.target.value)} placeholder='Enter Email Address' name='email' id='email' />
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='from-group'>
                                    <label htmlFor='password'> Password</label>
                                    <input type={passwordvisibilty ? "text" : "password"} className='form-control password' onChange={(e) => setPassword(e.target.value)} placeholder='Enter Password Address' name='password' id='password' />

                                    <button type='button' className='visibilty-btn' onClick={() => { setPasswordvisibilty(!passwordvisibilty) }}>
                                        {passwordvisibilty ? <Visibility /> : <VisibilityOff />}
                                    </button>
                                </div>
                            </div>

                            <div className='col-12'>
                                <div className='from-group from-btn'>
                                    <button type='submit' className='btn btn-common'>Login</button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>

            </div>
        </>
    );
}

export default Login;
