import React, { useEffect, useState } from 'react';
import __img_icon from '../../Assets/Image/white-Feather.png';
import { Indexdata } from '../../AllContent/Content';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

const Question = () => {

     const [rotation, setRotation] = useState(0);
     const [lastScrollY, setLastScrollY] = useState(0);
     const maxRotation = 100; // Maximum rotation in degrees


     useEffect(() => {
          const handleScrollrotate = () => {
               const currentScrollY = window.scrollY;

               if (currentScrollY > lastScrollY) {
                    // Scrolling down
                    setRotation((prev) => Math.min(prev + 1, maxRotation));
               } else if (currentScrollY < lastScrollY) {
                    // Scrolling up
                    setRotation((prev) => Math.max(prev - 1, -maxRotation));
               }

               setLastScrollY(currentScrollY);
          };

          window.addEventListener('scroll', handleScrollrotate);

          return () => {
               window.removeEventListener('scroll', handleScrollrotate);
          };
     }, [lastScrollY]);


     return (
          <ParallaxProvider>

          <section className='accordition'>
               <div className='__container'>
               <Parallax speed={20}> 
                    <div className='row'>

                         <div className='col-12 '>
                              <div className='animation-sec'>
                                   <div className='__father' style={{ transform: `translate(-60%, -70%) rotate(${rotation}deg)` }}>
                                        <img src={__img_icon} alt='icon' />
                                   </div>
                                   <h3> {Indexdata.Question.head.subHeading}</h3>
                                   <h1 style={{ transform: `translateX(${rotation - 100}px)` }} > #{Indexdata.Question.head.heading}</h1>
                              </div>
                         </div>

                         {Indexdata.Question.Faqwrap.map((item, index) => (
                              <div className='col-12' key={index}>
                                   <div className={"__fqr  number_" + item.key}>
                                        <p> {item.key}</p>
                                        <h6>{item.headingfaq}</h6>
                                        <p>{item.pragrphfaq} </p>
                                   </div>
                              </div>
                         ))}
                    </div>
                    </Parallax>
               </div>
          </section>
          </ParallaxProvider>
     );
}

export default Question;
